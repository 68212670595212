:root {
    /* Brand colors */
    --color-b-primary: #000000;
    --color-b-digital-blue:#1500FF;
    --color-b-grey:#939598;
    --color-b-mid-grey:#B7B9BC;
    --color-b-white: #FFFFFF;

    /* Custom Colors */
    --color-orange: #ee671c;
    --color-yellow: #f7da3b;
    --color-dark: #333333;

    /* SWIPPER  (SLIDER) */
    --swiper-theme-color: var(--color-b-digital-blue) !important;
}

body {
    font-family: "PTRootUIRegular";
    min-height: 100vh;
}

#root{
    min-height: 100vh;
}

/* ? Using On Main block */
.circle {
    width: 350px;
    height: 350px;
    border-radius: 50%;
    background-color: #2d3436;
    background-image: linear-gradient(315deg, #2d3436 0, var(--color-b-primary) 74%);
}

@media screen and (max-width: 768px) {
    .hero-content {
        position: absolute;
        z-index: 2;
    }

    .circle {
        position: absolute;
        top: -10rem;
        z-index: 1;
    }
}

/* Next level of | cursor */
.typed-cursor {
    color: var(--color-b-digital-blue);
}

/* USED ALL */
.line-above {
    border-top: 2px solid var(--color-b-digital-blue);
}

.flickity-enabled{
    outline: none;
}

.img-invert{
    filter: invert(1);
}

.line-hor-75{
    margin: auto;
    height: 1px;
    width: 75%;
}

.max-width-350px{
    max-width: 350px;
}