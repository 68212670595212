// СТОООП!
// Этот файл создан для кастомизации цветов и стилей "bulma"
@charset "utf-8";
@import "../../../node_modules/bulma/sass/utilities/initial-variables";
@import "../../../node_modules/bulma/sass/utilities/functions";

// Your custom variables
/* ?Brand colors */
$color-b-primary: #000000;
$color-b-digital-blue: #1500FF;
$color-b-grey: #939598;
$color-b-mid-grey: #B7B9BC;
$color-b-white: #FFFFFF;

/* ?Any colors */
$color-black: var(--color-b-primary);
$color-blue: var(--color-b-digital-blue);
$color-orange: #ee671c;
$color-yellow: #f7da3b;
$color-dark: #333333;

$black: $color-b-primary;
$white: $color-b-white;
$dark: $color-dark;
$primary: $color-b-digital-blue;
$link: $color-b-digital-blue;


// Добавление кастомных цветов
$color-orange-invert: findColorInvert($color-orange);
$color-yellow-invert: findColorInvert($color-yellow);
$custom-colors: (
    "orange": ($color-orange, $color-orange-invert),
    "yellow": ($color-yellow, $color-yellow-invert),
);

// Size is-SIZExSIZE
$dimensions: 16 24 32 48 64 96 128 160;

.button.is-primary.has-background-black:hover {
    background-color: $primary !important;
}

// Menu list
.menu-list > li  {
    border-bottom-width: 1px;
    border-bottom-color: $grey-lighter;
    border-bottom-style: solid;
}


.menu-list > li:last-child {
    border-bottom: none;
}

.menu-list > li > a ~ ul {
    display: none;
}

.menu-list > li > a > span.close {
    transition: 0.2s;
    transform: rotate(0deg);
}

.menu-list > li > a.is-active ~ ul{
    display: flex;
}

.menu-list > li > a.is-active > span.close{
    transform: rotate(45deg);
}



@import "../../../node_modules/bulma/bulma.sass";


// Mixins: https://bulma.io/documentation/utilities/responsive-mixins/
$f-mobile: 0;
$f-tablet: 769px;
$f-desktop: 1024px;

.custom-is-height-200px-mobile {
    @include until($f-tablet) {
        height: 200px;
    }
}

.custom-is-height-400px-tablet {
    @include from($f-tablet) {
        height: 400px;
    }
}


.button {
    border-radius: 0px;
}


/* ? CUSTOM BULMA CLASSES */
.custom-is-height-100p {
    height: 100%;
}

.custom-is-height-32px {
    height: 32px;
}

.custom-is-height-150px {
    height: 150px;
}

.custom-is-height-300px {
    height: 300px;
}

.custom-is-height-400px {
    height: 400px;
}

.custom-is-height-max-400px {
    max-height: 400px;
}

.custom-is-height-500px {
    height: 500px;
}

.custom-is-height-max-500px {
    max-height: 500px;
}

.custom-is-width-420px {
    width: 420px;
}

.custom-is-width-max-420px {
    max-width: 420px;
}

// BORDERS
.custom-has-border-t-none{
    border-top-width: 0px;
}

.custom-has-border-t{
    border-top-width: 1px;
}

.custom-has-border-r-none{
    border-right-width: 0px;
}

.custom-has-border-r{
    border-right-width: 1px;
}

.custom-has-border-b-none{
    border-bottom-width: 0px;
}

.custom-has-border-b{
    border-bottom-width: 1px;
}

.custom-has-border-l-none{
    border-left-width: 0px;
}

.custom-has-border-l{
    border-left-width: 1px;
}

.custom-has-border-style-solid{
    border-style: solid;
}

.custom-has-border-dark{
    border-color: $dark;
}

.custom-has-border-grey-lighter{
    border-color: $grey-lighter;
}
