@font-face {
  font-family: "PTRootUIBold";

  src: url("../fonts/pt_root_ui_bold/pt_root_ui_bold.eot");

  src: url("../fonts/pt_root_ui_bold/pt_root_ui_bold.eot");
  src: url("../fonts/pt_root_ui_bold/pt_root_ui_bold.woff2") format("woff2"),
    url("../fonts/pt_root_ui_bold/pt_root_ui_bold.woff") format("woff"),
    url("../fonts/pt_root_ui_bold/pt_root_ui_bold.ttf") format("truetype"),
    url("../fonts/pt_root_ui_bold/pt_root_ui_bold.ttf") format("ttf");
}

@font-face {
  font-family: "PTRootUILight";
  src: url("../fonts/pt_root_ui_light/pt_root_ui_light.eot");
  src: url("../fonts/pt_root_ui_light/pt_root_ui_light.woff2") format("woff2"),
    url("../fonts/pt_root_ui_light/pt_root_ui_light.woff") format("woff"),
    url("../fonts/pt_root_ui_light/pt_root_ui_light.ttf") format("truetype"),
    url("../fonts/pt_root_ui_light/pt_root_ui_light.ttf") format("ttf");
}

@font-face {
  font-family: "PTRootUIMedium";
  src: url("../fonts/pt_root_ui_medium/pt_root_ui_medium.eot");
  src: url("../fonts/pt_root_ui_medium/pt_root_ui_medium.woff2") format("woff2"),
    url("../fonts/pt_root_ui_medium/pt_root_ui_medium.woff") format("woff"),
    url("../fonts/pt_root_ui_medium/pt_root_ui_medium.ttf") format("truetype"),
    url("../fonts/pt_root_ui_medium/pt_root_ui_medium.ttf") format("ttf");
}

@font-face {
  font-family: "PTRootUIRegular";
  src: url("../fonts/pt_root_ui_regular/pt_root_ui_regular.eot");
  src: url("../fonts/pt_root_ui_regular/pt_root_ui_regular.woff2")
      format("woff2"),
    url("../fonts/pt_root_ui_regular/pt_root_ui_regular.woff") format("woff"),
    url("../fonts/pt_root_ui_regular/pt_root_ui_regular.ttf") format("truetype"),
    url("../fonts/pt_root_ui_regular/pt_root_ui_regular.ttf") format("ttf");
}
