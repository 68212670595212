.line {
  border-left: 6px solid blue;
  padding-right: 10px;
  height: 40px;
}

article {
  overflow: hidden;
  max-height: 0;
  height: auto;
  margin-top: -2px;
  
  display: flex;
  align-items: center;
  
  transition: all 0.3s ease;
}

input:checked ~ article {
  transition: all 0.3s ease-in-out;
  max-height: 500px;
}
