.how_to_enter__steps{
    display: flex;
    flex-direction: column;
    justify-content: center;
}


.how_to_enter__steps .middle-column {
    margin: 0 20px;
}
.how_to_apply__step {
    display: flex;
    flex-direction: row;
    align-items: center;

    margin-bottom: 20px;
}

.how_to_apply__step__number{
    text-align: center;
    min-width: 30px;

    font-size: 3rem;
}

.how_to_apply__step__image {
    max-height: 92px;
    max-width: 92px;
}

.how_to_apply__step__inner_block {
    display: flex;
    flex-direction: column;
}

.how_to_apply__step__inner_block__title{
    font-size: 1.5em;
    font-weight: 600;
}
.how_to_apply__step__inner_block__text{

}


.plan-icon-arrow{
    display: flex;
    justify-content: center;
    align-items: center;

    width: 100%;
    height: 100%;
}


@media screen and (max-width: 768px) {
    .how_to_apply__step__image {
        height: 80px;
        width: 80px;

        max-height: 80px;
        max-width: 80px;
    }
}

@media (min-width: 1216px) and (max-width: 1407px) {
    .how_to_apply__step__image {
        height: 80px;
        width: 80px;

        max-height: 80px;
        max-width: 80px;
    }
}

@media screen and (min-width: 1200px) {
    .howtoetext {
        white-space:nowrap;
    }
}

@media screen and (min-width: 768px) and (max-width: 1215px) {
    .how_to_apply__step__image {
        height: 80px;
        width: 80px;

        max-height: 80px;
        max-width: 80px;
    }
}

.orentation {
    display: flex;
    flex-direction: column;
    align-items: stretch;    
    height: 100%;
}

.orientation_title {
    text-align: center;
    font-size: 15px;
    border-left: 2px solid #000;
	border-right: 2px solid #000;
    border-top: 2px solid #000;
	padding: 1.5em;
    background-color: #000;
    font-weight: bold;
    color:#fff;
}

.orientation_text {
    text-align: center;
    border-left: 2px solid #000;
	border-right: 2px solid #000;
	border-bottom: 2px solid #000;
    border-top: 2px solid #000;
    padding: 0.3em;

    flex: 1;

    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

@media screen and (max-width: 895px) {
    .columns-wrap{
        flex-direction: column;
    }
}
